import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import TextField from "@material-ui/core/TextField";
import CustomHeader from "../../components/header/index.jsx";
// import PlantUML from "react-plantuml";
import plantumlEncoder from "plantuml-encoder";
import './aichat.scss';
import { Container, Col, Row, Button, Form } from 'react-bootstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from 'react-router-dom';
import url from "../../config.js"
import styled from 'styled-components';
import socketIOClient from "socket.io-client";
// import aiCard from '../../components/aiCard';
import AiCard from "../../components/aiCard"
import raml from '../../assets/icons/raml.png'
import dependency from '../../assets/icons/dependency.png'
import xml from '../../assets/icons/xml.png';
import loading from '../../assets/icons/loading-image.gif';
import plantuml from '../../assets/icons/plantuml.jpg'


// const plantUMLCode = `
//   @startuml
//   Alice -> Bob: Test Message
//   Bob -> Alice: Reply Message
//   @enduml
// `;
// const encodedDiagram = plantumlEncoder.encode(plantUMLCode);
// const plantUMLUrl = `https://www.plantuml.com/plantuml/svg/${encodedDiagram}`;
const Title = styled.p`
  color: #fff;
  font-weight: 100;
  @media (max-width: 100px) {
    font-size: 0.2rem;
  }
`
const Date = styled.div`
  color: #ccc;
  font-weight: 300;
  margin: 5px 0;
  
  @media (max-width: 10px) {
    font-size: 0.8rem;
  }
`
const Description = styled.p`
  color: #fff;
  font-weight: 300;
  padding:10px;
  @media (max-width: 10px) {
    font-size: 0.75rem;
  }
`


const domain = url.domain;
// const archetypesStack=myConst.archetypesStack
// const cloudStack= myConst.cloudStack
// const techStack = myConst.techStack

function AIChat() {
  let { projId, projName } = useParams();
  const navigate = useNavigate()

  const [state, setState] = useState({
    chat: '',
    chatResponse: '',
    // headerData: {"test":"dfgd"},
    // footerData: {"ggg":'dsdsds'},
    page: 'chatdashboard',
    code: '',
    getresponse: false,
    activeCode:'chatdashboard',
    loading:true

  }
  )

  useEffect(() => {
    // var url = "//localhost:" + (window.location.protocol === "https:" ? "3334" : "8083");
    // var url = "https://qa.istika.njclabs.com:443";


    var socketUrl = url.SOCKET

    console.log("URLLLLLLLL", socketUrl)
    const socket = socketIOClient(socketUrl, { rejectUnauthorized: true });
    console.log("socket ai chat", socket)





    socket.on("connect", function (e) {
      console.log("connect", e);
      console.log("STATE", state.projects);

    });
    socket.on('chat message', function (response) {
      console.log("MESSAAA", response)
      if(response!== undefined)
      setState(prevState => ({ ...prevState, chatResponse: response.message, getresponse: true,loading:false }));
      localStorage.setItem('chatResponse', response.message)

    });
    socket.on('raml data', function (response) {
      console.log("raml data", response)
      setState(prevState => ({ ...prevState, ramlData: response.message,loading:false }));
      localStorage.setItem('ramlData', response.message)


    });
    socket.on('dependency data', function (response) {
      console.log("dependency data", response)
      setState(prevState => ({ ...prevState, dependencyData: response.message,loading:false }));
      localStorage.setItem('dependencyData', response.message)


    });
    socket.on('xml data', function (response) {
      console.log("xml data", response)
      setState(prevState => ({ ...prevState, xmlData: response.message,loading:false }));
      localStorage.setItem('xmlData', response.message)


    });
    socket.on('plantUML', function (response) {
      console.log("plantuml data", response)
  
      setState(prevState => ({ ...prevState, plantUML: response.message,loading:false }));
      localStorage.setItem('plantUML', response.message)


    });
  })




  function onChange(event) {
    const { value } = event.target;
    setState(prevState => ({ ...prevState, chat: value }));
  }
  function onUMLEdit(event) {
    const { value } = event.target;
    setState(prevState => ({ ...prevState, plantUML: value }));
    localStorage.setItem('plantUML', value)
  }


  const submit = () => {
    setState(prevState => ({ ...prevState, chatResponse: '',loading:true }));
    // localStorage.setItem('message',state.chat)

    // navigate(`/projectDetails/${projName}/${projId}`);
    var postObj =
    {
      message: state.chat,
      searchfrom:state.activeCode
     
    }

    console.log("post aiChat", postObj)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
      body: JSON.stringify(postObj)
    };
    console.log("Inside submit", domain, requestOptions)
    fetch(domain + '/api/v1/aichat', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
      });

  }

  const goBuild = () => {
    // setState(prevState => ({ ...prevState, chatResponse: '' }));
    localStorage.setItem('message', state.chat)

    navigate(`/projectDetails/${projName}/${projId}`);
  }
  const goBack = () => {
    setState(prevState => ({ ...prevState, page: 'chatdashboard' }));

  }

  const onClick = (id) => {
    // let code = (id == 'raml') ? state.ramlData : (id == 'dependency') ? state.dependencyData : state.xmlData
    console.log("inside onclick", id)
    setState(prevState => ({ ...prevState, page: 'code', activeCode:id }));

  }

  const getModalBody = () => {
    if (state.page === 'chatdashboard')
      return (

        <>
          <Row className="projects-header-wrap">

            <Col xs={3}>
              <div className="text-input-wrap">
                <select className="browser-default custom-select" name='gitType' onChange={onChange}>
                  <option value="model">Select Model </option>

                  <option value="github"> Chat gpt </option>
                  <option value="bitbucket"> AWS bedrock </option>

                </select>

              </div>
            </Col>
            <Col xs={5} />
            <Col xs={4}>
              <div className="status-header">Prompt Suggessions</div>
            </Col>
          </Row>
          <Row >

            <Col xs={8}>
              <Row >
                <Col xs={10}>
                  <div className="ai-input-wrap">
                    {/* <input className="chat-text-input" placeholder="Project Name" name='projectName' onChange={onChange}></input> */}
                    <TextField
                      className="chat-text-input"
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="chat"
                      placeholder="Enter the message"
                      name="chat"
                      onChange={(e) => onChange(e)} />
                  </div>

                </Col>
                <Col xs={2} className="chat-button-wrap">
                  <Button variant="primary" size="sm" onClick={() => submit()}>
                    Send
                  </Button>
                </Col>
              </Row>
              {(state.getresponse)?
              <>
              
                <Row className="projects-result-wrap" >

                  <Col xs={3}>
                    <AiCard  theme="light" logo={raml} id='raml' description='Review Spec' onClick={onClick} />
                  </Col>
                  <Col xs={3}>
                    <AiCard  theme="light" logo={dependency} id='dependency' description='Review Dependencies' onClick={onClick} />
                  </Col >
                  <Col xs={3}>
                    <AiCard  theme="light" logo={xml} id='xml' description='Review Code' onClick={onClick} />
                  </Col>
                  <Col xs={3}>
                    <AiCard  theme="light" logo={plantuml} id='plantuml' description='PlantUML ' onClick={onClick} />
                  </Col>
                </Row>
                <Row >
                  <div className="chat-button2-wrap">
                    <Button variant="primary" size="sm" onClick={() => goBuild()}>
                      Go Build
                    </Button>
                  </div>
                </Row>
              </>
             :null
             }
            </Col>
            <Col xs={4} className="project-side-desc-wrap">
              <div className="aichat-status-wrap">
                <Row className="full-width status-div">
                  <ul>
                    <li>generate mule source code with https listener and apikit router to create, update and read lead, opportunity objects in salesforce with mule salesforce connector as a dependency in pom and also provide raml</li>
                    {/* <li>I need a mule source code with http listener to create an oppportunity in salesforce with mule salesforce connector as a dependency in pom and also provide proper raml</li> */}
                   {/* <li>We need a mule source code with http listener to create an oppportunity in salesforce with mule salesforce connector as a dependency in pom and also provide proper raml</li> */}
                    {/* <li>We need a mule source code with http:listener-connection to create an oppportunity in salesforce with mule-salesforce-connector as a dependency in pom with salesforce:basic-connection with username and password and without salesforce objects tag in the source code and xml should contain documentation in name space and also provide proper raml</li> */}
                    <li>Create a PlantUML sequence diagram for payment processing with SITS, MuleAPI, Queue, StripeAPI, DLQ, FinanceSystem, and AggressoFee.
SITS pushes payment details to MuleAPI.
MuleAPI publishes a payment event to Queue.
MuleAPI calls StripeAPI to process the payment.
On success, payment details are inserted into FinanceSystem and AggressoFee.
On failure, an error message is pushed to DLQ.
If the transaction commits, inserts are committed.
If it rolls back, an error is sent to DLQ.</li>
                  </ul>
                </Row>
              </div>
            </Col>

          </Row>


        </>
      )
    else {
          const encodedDiagram = plantumlEncoder.encode(state.plantUML);
          const plantUMLUrl = `https://www.plantuml.com/plantuml/svg/${encodedDiagram}`;
          const colSizeVal = (state.activeCode==='plantuml'?8:10)
          const backBtncolSizeVal = (state.activeCode==='plantuml'?7:9)

    
      return (
        <>
          <Row className="projects-header-wrap">
          <Col xs={colSizeVal}>
          {state.activeCode==='plantuml'?
           
             
            
              <div className="plantuml-wrap" style={{ 'height': '350px', 'overflow-y': 'scroll', 'whiteSpace': 'pre-wrap' }}>
              <img src={plantUMLUrl} alt="PlantUML Diagram" />
              {/* <PlantUML diagram={plantUMLCode} server="https://www.plantuml.com/plantuml/svg" /> */}

            </div>
            
           
             :
            
              <div className="aicode-wrap" style={{ 'height': '350px', 'overflow-y': 'scroll', 'whiteSpace': 'pre-wrap' }}>
                {state.loading?<img src={loading} class="loading-image" alt="" />:state.activeCode==='raml'?state.ramlData:state.activeCode==='xml'?state.xmlData:state.dependencyData}

                {/* {state.chatResponse} */}
              </div>
             
              }
               </Col>
           
            {state.activeCode==='plantuml'?
            <Col xs={4}>
            <textarea
             className="ai-plantuml-wrap outline-none"
             type="text"
             id="plantUML"
             value={state.plantUML}
                onChange={(e) => onUMLEdit(e)}
              />
            </Col>:<></>
    }
            <Col xs={colSizeVal}>
              <TextField
                className="chat-input"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="chat"
                placeholder="Enter the message"
                name="chat"
                onChange={(e) => onChange(e)}
              />
            </Col>
          </Row>

          <Row className="projects-header-wrap">
            <Col xs={backBtncolSizeVal}>
              <Button variant="primary" size="sm" onClick={() => goBack()}>
                Back
              </Button>
            </Col>
            <Col xs={3}>
              <Button variant="primary" size="sm" onClick={() => submit()}>
                Send
              </Button>
            </Col>
          </Row>

        </>
      )

    }


  }





  console.log("state", state)
  return (
    <div className="root" >
      <CustomHeader></CustomHeader>
      <main className="content">

        <div className="project-overview-wrap">
          <Container className="project-overview-container" className={!state.showModal ? 'show' : 'hide'}>

            <Row className="project-container-row">
              <Col xs={12} className=" projects-container">
                <Row className="projects-header-wrap">
                  <Col xs={8}>
                    <div className="sub-header">Talk to our SmartHub Assist</div>
                  </Col>


                </Row>

                {getModalBody()}


              </Col>
            </Row>
          </Container>
        </div>
      </main>
    </div>
  );
}

export default AIChat;